import { graphQuery, graphQueryBang, parseGraphValidationErrors } from "shared/lib/graphql"
import {
  AppletFilterCodeValidationMutation,
  AppletTriggerFieldValidationMutation,
  AppletQueryFieldValidationMutation,
  AppletActionFieldValidationMutation,
  AutoActivateMutation,
  AIAppletSuggestionQuery,
  DIYAppletEditMutation,
  DiyAppletAIMakerCreateMutation,
  DIYCreateAppletMutation,
  DIYLoadActionFields,
  DIYLoadQueryFieldsWithIngredientsQuery,
  DIYLoadTriggerFieldsWithIngredientsQuery,
  DIYLoadActionDefaultValuesQuery,
  DIYLoadQueryDefaultValuesQuery,
  FilterCodeGeneratorsQuery,
  LoadPrivateServicesQuery,
  NormalizedAppletEnableMutation,
  StatementPreviewMutation,
} from "./queries"
import {
  Action,
  AISuggestionTQAShape,
  DIYComposerState,
  Payload,
  Query,
  StepType,
  StoredFieldProps,
  Trigger,
  UpgradeTier,
  ValidationErrors,
} from "./utils/interfaces"
import { TQAType } from "."

interface BrandOwnerDIYProps {
  serviceSlug: string
  trigger: Trigger
  actions: Array<Action>
}

const brandOwnerDIY = ({ serviceSlug, trigger, actions }: BrandOwnerDIYProps) => {
  if (serviceSlug === trigger.service.module_name) {
    return trigger.service.id
  } else {
    return actions[0].service.id
  }
}

const formatValue = (field_ui_type: string, value: string) => {
  if (value) {
    return value
  } else if (field_ui_type === "checkbox_multi") {
    return []
  } else {
    return ""
  }
}

const formatFields = (step: TQAType) =>
  Object.values(step.fields)
    .sort((f1, f2) => f1.order - f2.order)
    .filter(f => f.name !== "_live_channel_id")
    .map(field => {
      const value = field.value
        ? JSON.stringify(formatValue(field.field_ui_type, field.value))
        : field.default_value_json
      return {
        name: field.name,
        hidden: field.hidden,
        value: value || JSON.stringify(""),
      }
    })

const formatStep = (step: TQAType) => ({
  step_identifier: step.full_module_name,
  fields: formatFields(step),
  channel_id: step.service.id,
  live_channel_id: step.live_channel_id,
})

interface CreateAppletProps {
  actions: Array<Action>
  delay: number
  description: string
  filterCode: string
  pushEnabled: boolean
  queries: Array<Query>
  serviceSlug: string
  trigger: Trigger
}

export const createApplet = ({
  actions,
  delay,
  description,
  filterCode,
  queries,
  serviceSlug,
  trigger,
}: CreateAppletProps) =>
  graphQueryBang(DIYCreateAppletMutation, {
    name: description,
    channel_id: brandOwnerDIY({
      trigger,
      actions,
      serviceSlug,
    }),
    filter_code: filterCode,
    trigger: formatStep(trigger),
    actions: actions.map(formatStep),
    queries: queries.map(formatStep),
    actions_delay: delay,
  })

interface EditAppletProps {
  actions: Array<Action>
  appletId: string
  delay: number
  filterCode: string
  name?: string
  queries: Array<Query>
  trigger: Trigger
}

export const editApplet = ({ appletId, name, trigger, queries, actions, filterCode, delay }: EditAppletProps) =>
  graphQueryBang(DIYAppletEditMutation, {
    actions_delay: delay,
    actions: actions.map(formatStep),
    applet_id: appletId,
    channel_id: trigger.service.id,
    filter_code: filterCode,
    name,
    queries: queries.map(formatStep),
    trigger: formatStep(trigger),
  })

const buildStoredField = (storedFields: object, fields: Array<StoredFieldProps>, stepLiveChannelId: string) => {
  return fields.reduce((acc, cur) => {
    if (!cur.hidden) {
      return {
        ...acc,
        [cur.owner]: {
          ...acc[cur.owner],
          _live_channel_id: stepLiveChannelId,
          [cur.name]: cur.value,
        },
      }
    } else {
      return acc
    }
  }, storedFields)
}

interface CommunityAppletProps extends DIYComposerState {
  metadata?: object
}

export const updateCommunityApplet = ({
  appletId,
  name,
  selectedTrigger,
  selectedActions,
  selectedQueries,
  metadata,
  filterCode,
  delay,
}: CommunityAppletProps) => {
  let storedFields = {}

  storedFields = buildStoredField(storedFields, selectedTrigger?.trigger_fields, selectedTrigger?.live_channel_id)

  selectedQueries.forEach(selectedQuery => {
    storedFields = buildStoredField(storedFields, selectedQuery.query_fields, selectedQuery.live_channel_id)
  })

  selectedActions.forEach(selectedAction => {
    storedFields = buildStoredField(storedFields, selectedAction.action_fields, selectedAction.live_channel_id)
  })

  return graphQueryBang(NormalizedAppletEnableMutation, {
    applet_id: appletId,
    name,
    preserve_applet_fields: false,
    dynamic_applet_configuration: false,
    stored_fields: storedFields,
    metadata,
    filter_code: filterCode,
    actions_delay: delay,
  })
}

export const loadPreview = ({
  trigger,
  triggerFields,
  action,
  actionFields,
  actionLiveChannelId,
  triggerLiveChannelId,
}) => {
  const formatFieldsKeyValue = fields => {
    return Object.values(fields).reduce(
      (ac, field) => ({
        ...ac,
        [field.name]: field.value,
      }),
      {}
    )
  }

  const storedFields = {
    trigger,
    trigger_fields: formatFieldsKeyValue(triggerFields),
    action,
    action_fields: formatFieldsKeyValue(actionFields),
    trigger_live_channel_id: triggerLiveChannelId,
    action_live_channel_id: actionLiveChannelId,
  }

  return graphQueryBang(StatementPreviewMutation, {
    stored_fields: storedFields,
  })
}

export const loadPrivateServices = async () => {
  const {
    me: { private_channels },
  } = await graphQueryBang(LoadPrivateServicesQuery)

  return private_channels.map(service => {
    const image = service.lrg_monochrome_image_url || service.lrg_variant_image_url
    delete service.lrg_monochrome_image_url
    delete service.lrg_variant_image_url

    return {
      ...service,
      brand_color: `#${service.brand_color}`,
      image,
    }
  })
}

export const loadFilterCodeGenerators = async (
  triggerIds: Array<string>,
  queryIds: Array<string>,
  actionIds: Array<string>
) => {
  const result = await graphQuery(FilterCodeGeneratorsQuery, {
    triggerIds,
    queryIds,
    actionIds,
  })

  return result.data.filter_code_generators
}

interface ValidateFilterCodeProps {
  filter_code: string
  trigger: Trigger
  queries: Array<Query>
  actions: Array<Action>
}

export function validateFilterCode({ filter_code, trigger, queries, actions }: ValidateFilterCodeProps) {
  return graphQueryBang(AppletFilterCodeValidationMutation, {
    filter_code,
    trigger: formatStep(trigger),
    actions: actions.map(formatStep),
    queries: queries.map(formatStep),
  }).then(response => response.appletFilterCodeValidation)
}

export function checkServiceActivation({ serviceModuleName }: { serviceModuleName: string }) {
  return graphQueryBang(AutoActivateMutation, {
    serviceModuleName,
    replace: false,
  })
}

export function validateStepField(stepType: string, payload: Payload): Promise<ValidationErrors> {
  const query = {
    trigger: AppletTriggerFieldValidationMutation,
    query: AppletQueryFieldValidationMutation,
    action: AppletActionFieldValidationMutation,
  }[stepType]

  return new Promise(resolve => {
    graphQuery(query, payload).then(response => {
      let errors = response?.data?.appletStepFieldValidation?.errors

      if (errors?.length) {
        errors = parseGraphValidationErrors(errors)
      }

      resolve(errors)
    })
  })
}

interface CreateAIMakerAppletProps {
  appletName: string
  triggerModuleNames: string
  queriesModuleName: Array<string>
  actionsModuleName: Array<string>
}

export function createAIMakerApplet({
  appletName,
  triggerModuleNames,
  queriesModuleName,
  actionsModuleName,
}: CreateAIMakerAppletProps) {
  return graphQueryBang(DiyAppletAIMakerCreateMutation, {
    name: appletName,
    trigger_module_name: triggerModuleNames,
    queries_module_name: queriesModuleName,
    actions_module_name: actionsModuleName,
  })
}

export function generateAIAppletSuggestions(user_description: string) {
  return graphQueryBang(AIAppletSuggestionQuery, { user_description })
}

export interface AISuggestionResponse {
  trigger: AISuggestionTQAShape
  queries: AISuggestionTQAShape[]
  actions: AISuggestionTQAShape[]
  enablement_rules: {
    block_reason: string | null
    block_user: boolean
    minimum_tier: UpgradeTier
  } | null
  errors: { attribute: string; message: string }[]
}

export async function fetchStepFieldsAndIngredients(type: StepType, step: TQAType) {
  let query
  switch (type) {
    case "trigger":
      query = DIYLoadTriggerFieldsWithIngredientsQuery
      break
    case "query":
      query = DIYLoadQueryFieldsWithIngredientsQuery
      break
    case "action":
      query = DIYLoadActionFields
  }

  return await graphQueryBang(query, { id: Number(step.id) }, { shouldRetryOnFailure: true })
}

export async function fetchStepDefaultValues(triggerModuleName: string, step: Action | Query, type: StepType) {
  const query = type === "query" ? DIYLoadQueryDefaultValuesQuery : DIYLoadActionDefaultValuesQuery
  return await graphQueryBang(
    query,
    {
      trigger_module_name: triggerModuleName,
      step_module_name: step.full_module_name,
    },
    { shouldRetryOnFailure: true }
  )
}
