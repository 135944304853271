import { useState, useEffect, useRef } from "react"
import { captureException } from "@sentry/react"
import { useBgColorContext } from "./context"

import ServiceBrand from "./shared/service_brand"
import StoredField from "app/components/stored_field/index"
import { fullColoredPage } from "./utils/container_background_color"
import { pluralizeStepType } from "shared/lib/utils"
import ErrorMessageForField from "./error_message"
import Spinner from "../../../shared/components/spinner"

import { validateStepField } from "./api"
import formatStoredField from "shared/lib/format_stored_field"
import { verifyIntendedPromiseRejection } from "./utils/modal_promise"
import {
  Action,
  LiveChannels,
  Payload,
  Query,
  StepField,
  StepType,
  StoredFieldProps,
  TQAType,
  Trigger,
  ValidationErrors,
} from "./utils/interfaces"

import {
  attachNewBadgeForMultiServiceAccountButton,
  removeMultiServiceAccountNewBadge,
} from "shared/scripts/new_badge_button"

import "./step_fields_selector.scss"

const parseDefaultStepFieldsValues = (
  stepType: StepType,
  stepDefaultStepFields: { [key: string]: { defaults_for_trigger?: string } } = {}
) => {
  try {
    return JSON.parse(stepDefaultStepFields[stepType]?.defaults_for_trigger || "{}")
  } catch (err) {
    //Send to Sentry. Pass context directly (tag and extra) only available on version 5.16+
    captureException(err)
    if (window.App.window.App.allowDebugErrorMessages) {
      console.warn("Error parsing default step values", err)
    }
  }
}

interface StepFieldsSelectorProps {
  addingStep: boolean
  changeServiceAccount: (live_channel_id: string) => void
  connectionFinishedUrl: string
  defaultValueLoader?: (step: Action | Query, stepType: StepType) => { [key: string]: object }
  editMode: boolean
  ingredientsMetadata?: object
  makeItYourOwnFeaturePrompt: () => void
  makingItTheirOwn: boolean
  onCreate: (step: TQAType, fields: object) => void
  proFeatureGate?: (permissionName: string) => Promise<void>
  queries?: Array<Query>
  sourceAppletId: string
  step: TQAType
  stepFields: Array<StepField>
  stepType: StepType
  trigger?: Trigger
  updateServiceAccountList: (live_channels: Array<LiveChannels>) => void
  userAllowMultipleLiveChannels: boolean
  userOwned: boolean
}

export default function StepFieldsSelector({
  addingStep,
  changeServiceAccount,
  connectionFinishedUrl,
  defaultValueLoader,
  editMode,
  ingredientsMetadata = {},
  makeItYourOwnFeaturePrompt,
  makingItTheirOwn,
  onCreate,
  proFeatureGate,
  queries,
  sourceAppletId,
  step,
  stepFields,
  stepType,
  trigger,
  updateServiceAccountList,
  userAllowMultipleLiveChannels,
  userOwned,
}: StepFieldsSelectorProps) {
  const bgColor = useBgColorContext()
  const stepPlural = pluralizeStepType(stepType)
  const stepSlug = `${stepPlural}/${step.full_module_name}`
  const isFieldEditable = (f: StepField) => !editMode || userOwned || makingItTheirOwn || !f.hidden
  const requiredStepFields = stepFields.filter(isFieldEditable).filter(f => f.required)

  const [defaultValues, updateDefaultValues] = useState<{ [key: string]: string }>({})
  const [defaultValuesLoaded, updateDefaultValuesLoaded] = useState(false)
  const fieldsReady = useRef(0)
  const [formEnabled, updateFormEnabled] = useState(false)
  const [fields, updateFields] = useState({})
  const [errors, updateErrors] = useState({})
  const [submittingStep, updateSubmittingStep] = useState(false)
  const [retryOptionsForService, forceRetryForService] = useState(false)
  const [showAloneNewBadge, updateAloneNewBadgeVisibility] = useState(
    userAllowMultipleLiveChannels === true && attachNewBadgeForMultiServiceAccountButton()
  )
  const submitRef = useRef(null)

  useEffect(() => {
    const effect = async () => {
      if (stepType === "action" || stepType === "query") {
        try {
          const response = await defaultValueLoader?.(step as Action | Query, stepType)

          const newDefaultValues = parseDefaultStepFieldsValues(stepType, response)
          updateDefaultValues(newDefaultValues)

          const newFields: { [key: string]: object } = {}
          Object.entries(newDefaultValues).forEach(([fieldName, value]) => {
            const stepField = stepFields.find(sf => sf.name === fieldName)
            newFields[fieldName] = {
              ...stepField,
              value: stepField?.value === null || stepField?.value === undefined ? value : stepField?.value,

              hidden: stepField?.hasOwnProperty("hidden") // true for importedState
                ? stepField.hidden
                : stepField?.hideable,
            }
            updateFields(newFields)
          })
        } finally {
          updateDefaultValuesLoaded(true)
        }
      }
    }
    effect()
  }, [])

  useEffect(() => {
    const hasErrors = Object.keys(errors).length > 0

    if (hasErrors && submitRef.current) {
      submitRef.current.blur()
    }
  })

  useEffect(() => {
    const composerEl = document.querySelector("#diy-composer")

    composerEl?.scrollIntoView({ block: "start" })
  }, [])

  useEffect(() => {
    fullColoredPage(bgColor)

    return () => {
      fullColoredPage("initial")
    }
  }, [bgColor])

  const onChange = (field: StepField, value: string) => {
    updateFields(previousFields => ({
      ...previousFields,
      [field.name]: {
        ...field,
        value,
        hidden: field.hasOwnProperty("hidden") // true for importedState
          ? field.hidden
          : field.hideable,
      },
    }))

    if (field.field_ui_type === "service_account_select") {
      changeServiceAccount(value)
    }
  }

  const onSubmit = async (e: Event) => {
    e.preventDefault()
    updateSubmittingStep(true)
    await validateWithGraph()
  }

  const validateWithGraph = async () => {
    try {
      const stepPayload = (step: Trigger | Query, fields: object) => ({
        step_identifier: step.full_module_name,
        channel_id: step.service.id,
        live_channel_id: step.live_channel_id,
        // Step validation only needs name and value. Hidden is used only for DIY mutation.
        fields: Object.values(fields)
          .filter(f => f.name !== "_live_channel_id")
          .map(field => ({
            name: field.name,
            hidden: field.hidden,
            value: JSON.stringify(field.value),
          })),
      })

      const payload: Payload = {
        [stepType]: stepPayload(step, fields),
        applet_id: sourceAppletId,
      }

      if (stepType === "query" || stepType === "action") {
        if (trigger) {
          payload["trigger"] = stepPayload(trigger, trigger.fields) // trigger.fields is an empty {}
        }
      }

      if (stepType === "action") {
        if (!queries) {
          console.error(`'queries' prop is required when stepType is '${stepType}'`)
          updateSubmittingStep(false)
          return
        }
        payload["queries"] = queries.map(query => stepPayload(query, query.fields))
      }

      validateStepField(stepType, payload).then((errors: ValidationErrors) => {
        const stepError = errors?.validation_errors?.stored_fields?.[`/${stepSlug}`]
        if (stepError) return onFailedValidation(stepError)

        onSuccessfulValidation()
      })
    } catch (err) {
      console.error(err)
      updateSubmittingStep(false)
    }
  }

  const onSuccessfulValidation = () => {
    onCreate(step, fields)
  }

  const onFailedValidation = (parsedErrors: Array<string>) => {
    updateSubmittingStep(false)
    updateErrors(parsedErrors)
  }

  const onFieldReady = (field: StepField) => {
    if (field.required) {
      fieldsReady.current += 1
    }

    if (fieldsReady.current === requiredStepFields.length) {
      updateFormEnabled(true) // one-way state change
    }
  }

  const componentForField = (field: StepField) => {
    const currentValue = addingStep ? defaultValues[field.name] : field.value

    // So we can submit a form with params
    const fieldName = `fields[${field.name}]`

    const storedField: StoredFieldProps = formatStoredField(stepType, step.full_module_name, field)

    if (storedField.field_subtype === "service_account_select") {
      storedField.options = step.service.live_channels
      storedField.updateOptionsList = liveChannels => updateServiceAccountList(liveChannels)
      storedField.connectionFinishedUrl = connectionFinishedUrl
      storedField.isOnColor = true
      storedField.value = step.live_channel_id
      storedField.showNewBadge = showAloneNewBadge
      storedField.hideNewBadge = () => {
        updateAloneNewBadgeVisibility(false)
        removeMultiServiceAccountNewBadge()
      }
    }

    const disabled = !isFieldEditable(field)

    return (
      <>
        <StoredField
          field={storedField}
          fieldName={fieldName}
          ingredientsMetadata={ingredientsMetadata}
          value={currentValue}
          onReady={() => (isFieldEditable(field) ? onFieldReady(field) : null)}
          onChange={(val: string) => onChange(field, val)}
          fieldOwnerServiceId={step.service.module_name}
          fieldOwnerServiceName={step.service.name}
          fieldOwnerAllowMultipleLiveChannels={step.service.allow_multiple_live_channels}
          userAllowMultipleLiveChannels={userAllowMultipleLiveChannels}
          isOnColor
          platformComposer
          disabled={disabled}
          liveChannelId={step.live_channel_id}
          updateFormEnabled={updateFormEnabled}
          shouldForceRetryOptions={retryOptionsForService}
          updateServiceToForceRetry={(val: boolean) => forceRetryForService(val)}
          proFeatureGate={proFeatureGate}
        />
        {disabled && (
          <div styleName="make-it-your-own">
            <span styleName="cta" onClick={verifyIntendedPromiseRejection(makeItYourOwnFeaturePrompt)} role="button">
              Make it your own
            </span>{" "}
            to edit this field
          </div>
        )}
      </>
    )
  }

  const fieldsList = () => {
    return stepFields
      .sort((a, b) => (a.order || 0) - (b.order || 0))
      .map(stepField => {
        return (
          <li key={stepField.name} className="field">
            <span className="field-name label">{stepField.label}</span>
            <span className="input">
              <ErrorMessageForField errors={errors} field={stepField.name} />
              {componentForField(stepField)}
            </span>
          </li>
        )
      })
  }

  if (stepFields.length === 0) {
    onSuccessfulValidation()
  }

  if ((stepType === "action" || stepType == "query") && !defaultValuesLoaded)
    return <Spinner config={{ color: "#fff" }} />

  const doneCTA = submittingStep
    ? `${addingStep ? "Creating" : "Updating"} ${stepType}...`
    : `${addingStep ? "Create" : "Update"} ${stepType}`

  return (
    <>
      <ServiceBrand description={step.description} image={step.service.image} title={step.name} />
      <div styleName="step-fields-selector">
        <form onSubmit={onSubmit} className="form">
          <div styleName="step-fields-container">
            <ul className="fields">{fieldsList()}</ul>

            <div styleName="cta">
              <input
                disabled={!formEnabled || submittingStep}
                ref={submitRef}
                type="submit"
                role="button"
                className="button-primary button-on-color"
                value={doneCTA}
              />
            </div>
          </div>
        </form>
      </div>
    </>
  )
}
